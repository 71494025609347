import {Footer} from "./components/Footer";
import React from "react";
import {Navbar} from "./components/Navbar";
import Landing from "./pages/Landing";
import {Route, Routes} from "react-router-dom";
import {Login} from "./pages/Login";

const App = () => {
    return (
        <div>
            <Navbar/>
            <Routes>
                <Route path={'/'} element={<Landing/>}/>
                <Route path={'/login'} element={<Login/>}/>
            </Routes>
            <hr/>
            <Footer/>
        </div>)
}
export default App;
