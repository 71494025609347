import './Navbar.css'
import React from "react";

export const Navbar = () => {
    return (
        <div className="navbar">
            <img className={'logo'} src={'./images/emoji.jpeg'} alt={'logo'}/>
            {/*<ul>*/}
            {/*    <li><Link href="/">Dashboard</Link></li>*/}
            {/*    <li><Link className={'link'} to={'/login'}>Login/Register</Link></li>*/}
            {/*</ul>*/}

        </div>
    )
}
