import Profile from "../components/Profile";
import Experience from "../components/Experience";
import React from "react";

export default function Landing() {
    return (
        <>
            <Profile/>
            <hr/>
            <Experience/>
        </>
    )
}
